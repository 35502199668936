<template>
  <div>
    <b-col cols="12">
      <h2>Статистика продаж и возвратов по кассирам</h2>
    </b-col>
    <b-overlay :show="loading" class="mb-4">
      <b-card class="mb-4 mt-4">
        <b-form-group>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Начиная с даты">
                <b-form-datepicker locale="ru" label-no-date-selected="Выберите дату" :start-weekday="1" v-model="filters.dateFrom" reset-button/>
              </b-form-group>
              <b-form-group label="Кассир">
                <b-form-select v-model="filters.cashier">
                  <option :value="null">Выберите кассира</option>
                  <option v-for="cashier in users" :value="cashier.id" :key="cashier.id">{{ cashier.email }}</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Заканчивая датой" class="mb-0">
                <b-form-datepicker locale="ru" label-no-date-selected="Выберите дату" :start-weekday="1" v-model="filters.dateTo" reset-button/>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <b-form-radio v-model="filters.type" :value="'carriers'">Показать в разрезе перевозчиков</b-form-radio>
                <b-form-radio v-model="filters.type" :value="'routes'">Показать в разрезе маршрутов</b-form-radio>
                <b-form-radio v-model="filters.type" :value="'carriers_routes'">Показать в разрезе перевозчиков и маршрутов</b-form-radio>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-form-radio v-model="filters.timeType" :value="'payment_type'">По времени оплаты</b-form-radio>
                <b-form-radio v-model="filters.timeType" :value="'booking_type'">По времени бронирования</b-form-radio>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>
        <div class="d-flex">
          <b-button variant="primary" @click="generateReport">Применить</b-button>
        </div>
      </b-card>
    </b-overlay>
    <div>
      <b-card class="mb-4 mt-4">
        <h3>Продажи</h3>
        <b-tabs content-class="mt-3">
          <b-tab title="Касса (наличными)" active>
            <b-table :items="data.sales.cash.data" :fields="salesFields" responsive></b-table>
          </b-tab>
          <b-tab title="ПСБ (интернет эквайринг)">
            <b-table :items="data.sales.cashless.data" :fields="salesFields" responsive></b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <b-card class="mb-4 mt-4">
        <h3>Возвраты</h3>
        <b-tabs content-class="mt-3">
          <b-tab title="Касса (наличными)" active>
            <b-table :items="data.refunds.cash.data" :fields="refundsFields" responsive foot-clone>
              <template #foot()="">
                <span></span>
              </template>
              <template #foot(baggage_cost)="">
                <span>{{ toRubles(0) }}</span>
              </template>
              <template #foot(tickets_count_rk)="">
                <span>{{ 0 }}</span>
              </template>
              <template #foot(orders_count)="item">
                <span v-if="data.refunds.cash.summary[item.field.key] !== undefined">{{ data.refunds.cash.summary[item.field.key] }}</span>
              </template>
              <template #foot(tickets_count)="item">
                <span v-if="data.refunds.cash.summary[item.field.key] !== undefined">{{ data.refunds.cash.summary[item.field.key] }}</span>
              </template>
              <template #foot(payment_amount)="item">
                <span v-if="data.refunds.cash.summary[item.field.key] !== undefined">{{ toRubles(data.refunds.cash.summary[item.field.key]) }}</span>
              </template>
              <template #foot(cost_trip_cost)="item">
                <span v-if="data.refunds.cash.summary[item.field.key] !== undefined">{{ toRubles(data.refunds.cash.summary[item.field.key]) }}</span>
              </template>
              <template #foot(refund_amount)="item">
                <span v-if="data.refunds.cash.summary[item.field.key] !== undefined">{{ toRubles(data.refunds.cash.summary[item.field.key]) }}</span>
              </template>
              <template #foot(cost_service_fee)="item">
                <span v-if="data.refunds.cash.summary[item.field.key] !== undefined">{{ toRubles(data.refunds.cash.summary[item.field.key]) }}</span>
              </template>
              <template #foot(cost_cash_box_fee)="item">
                <span v-if="data.refunds.cash.summary[item.field.key] !== undefined">{{ toRubles(data.refunds.cash.summary[item.field.key]) }}</span>
              </template>
              <template #foot(refund_service_fee)="item">
                <span v-if="data.refunds.cash.summary[item.field.key] !== undefined">{{ toRubles(data.refunds.cash.summary[item.field.key]) }}</span>
              </template>
              <template #foot(refund_cash_box_fee)="item">
                <span v-if="data.refunds.cash.summary[item.field.key] !== undefined">{{ toRubles(data.refunds.cash.summary[item.field.key]) }}</span>
              </template>
              <template #foot(deducted_from_tickets)="item">
                <span v-if="data.refunds.cash.summary[item.field.key] !== undefined">{{ toRubles(data.refunds.cash.summary[item.field.key]) }}</span>
              </template>
              <template #foot(deducted_service_fee)="item">
                <span v-if="data.refunds.cash.summary[item.field.key] !== undefined">{{ toRubles(data.refunds.cash.summary[item.field.key]) }}</span>
              </template>
              <template #foot(deducted_cash_box_fee)="item">
                <span v-if="data.refunds.cash.summary[item.field.key] !== undefined">{{ toRubles(data.refunds.cash.summary[item.field.key]) }}</span>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="ПСБ (интернет эквайринг)">
            <b-table :items="data.refunds.cashless.data" :fields="refundsFields" responsive>
              <span class="text-danger">{{ data.label }}</span>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import ReportsApi from "@/api/reports.api";
import {toRubles} from "@/utils/money";
import {mapState} from "vuex";
import {SALES_FIELDS, REFUNDS_FIELDS} from "@/pages/Reports/fields/SalesAndRefundsByCashiers";

export default {
  name: "SalesAndRefundsByCashiers",
  data() {
    return {
      loading: false,
      filters: {
        cashier: null,
        dateFrom: null,
        dateTo: null,
        timeType: 'payment_type',
        type: 'carriers'
      },
      loadedFilters: {
        timeType: 'payment_type',
        type: 'carriers'
      },
      data: {
        sales: {
          cash: [],
          cashless: []
        },
        refunds: {
          cash: [],
          cashless: []
        }
      }
    }
  },
  methods: {
    toRubles(amount) {
      return toRubles(amount);
    },
    generateReport() {
      if (!this.filters.dateFrom || !this.filters.dateTo) {
        alert('Даты опязательны для заполнения');
        return;
      }

      if (!this.filters.cashier) {
        alert('Необходимо выбрать кассира.');
        return;
      }

      this.loading = true

      ReportsApi.salesAndRefunds({
        dateFrom: this.filters.dateFrom,
        dateTo: this.filters.dateTo,
        timeType: this.filters.timeType,
        type: this.filters.type,
        cashier: this.filters.cashier
      }).then(response => {
        this.data = response.data;
      }).finally(() => {
        this.loadedFilters.timeType = this.filters.timeType
        this.loadedFilters.type = this.filters.type

        this.loading = false
      });
    }
  },
  computed: {
    ...mapState({
      users: state => state.Users.items
    }),
    salesFields() {
      let data = [];

      if (this.loadedFilters.type === 'carriers') {
        data = [
          {
            key: 'carrier_name',
            label: 'Перевозчик'
          }
        ]
      } else if (this.loadedFilters.type === 'routes') {
        data = [
          {
            key: 'route_name',
            label: 'Маршрут'
          }
        ]
      } else if (this.loadedFilters.type === 'carriers_routes') {
        data = [
          {
            key: 'carrier_name',
            label: 'Перевозчик'
          },
          {
            key: 'route_name',
            label: 'Маршрут'
          }
        ]
      }

      return [
        ...data,
        ...SALES_FIELDS
      ]
    },
    refundsFields() {
      let data = [];

      if (this.loadedFilters.type === 'carriers') {
        data = [
          {
            key: 'carrier_name',
            label: 'Перевозчик'
          }
        ]
      } else if (this.loadedFilters.type === 'routes') {
        data = [
          {
            key: 'route_name',
            label: 'Маршрут'
          }
        ]
      } else if (this.loadedFilters.type === 'carriers_routes') {
        data = [
          {
            key: 'carrier_name',
            label: 'Перевозчик'
          },
          {
            key: 'route_name',
            label: 'Маршрут'
          }
        ]
      }

      return [
        ...data,
        ...REFUNDS_FIELDS
      ]
    }
  }
}
</script>

<style scoped>

</style>