import httpClient from "./http.api";

const api = {
    tripReport(params = {}) {
        return httpClient.post('reports/trip-report', {
            params
        })
    },
    salesByCarriers(params = {}) {
        return httpClient.post('reports/sales-by-carriers', {
            ...params
        })
    },
    salesByCarriersExcel(params = {}) {
        return httpClient.get('reports/sales-by-carriers-excel', {
            params,
            responseType: 'blob'
        })
    },
    salesAndRefunds(params = {}) {
        return httpClient.post('reports/sales-and-refunds', {
            ...params
        })
    },
    salesAndRefundsExcel(params = {}) {
        return httpClient.get('reports/sales-and-refunds-excel', {
            params,
            responseType: 'blob'
        })
    },
    detailedSalesAndRefunds(params = {}) {
        return httpClient.post('reports/detailed-sales-and-refunds', {
            ...params
        })
    },
    detailedSalesAndRefundsExcel(params = {}) {
        return httpClient.post('reports/detailed-sales-and-refunds-excel', {
            ...params,
            responseType: 'blob'
        })
    },
}

export default api;